// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MS41IiBoZWlnaHQ9IjcxLjUiPjxwYXRoIGQ9Ik05LjU3MyAzNi45MzdxLS43ODkuNzg5LTEuODgyLS40MjVWNi44ODZxMS4xNTQtMS4yMTQgMS44ODItLjQyNUwzNC40IDIwLjU0NWExLjYxNCAxLjYxNCAwIDAgMSAwIDIuMzA3eiIgc3R5bGU9ImZpbGw6I2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuOTM4IDE0LjA1MSkiLz48cGF0aCBkPSJNMzUuNzUgMi45NzlhMzIuNzcxIDMyLjc3MSAwIDAgMC0yMy4xNzIgNTUuOTQzIDMyLjc3MSAzMi43NzEgMCAwIDAgNDYuMzQ0LTQ2LjM0NCAzMi41NTYgMzIuNTU2IDAgMCAwLTIzLjE3Mi05LjZtMC0yLjk3OEEzNS43NSAzNS43NSAwIDEgMSAwIDM1Ljc1IDM1Ljc1IDM1Ljc1IDAgMCAxIDM1Ljc1IDB6IiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-instaWidgetCard .videoPlayButton{width:var(--play-button-size);height:var(--play-button-size);position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:center;background-size:contain;background-color:rgba(0,0,0,.3);border-radius:50%}.ds-instaWidgetCard{position:relative;height:330px;border-radius:var(--rad);overflow:hidden;--play-button-size: 36px}.ds-instaWidgetCard.hovered .ds-instaWidgetCard__thumbnail,.ds-instaWidgetCard.hovered .ds-instaWidgetCard__text{display:none}.ds-instaWidgetCard__thumbnail{position:absolute;inset:0;object-fit:cover}.ds-instaWidgetCard__text{background:linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.698) 25.98%, rgba(0, 0, 0, 0.432) 56.06%, rgba(0, 0, 0, 0.0954) 82.04%, rgba(0, 0, 0, 0) 91.15%);position:absolute;bottom:0;width:100%;z-index:1;color:#fff;padding:100px 12px 12px}.ds-instaWidgetCard__text b{display:block;margin-bottom:12px}.ds-instaWidgetCard__text span{display:inline-block;border-radius:var(--rad);background:rgba(37,37,37,.8);padding:4px;line-height:12px}`, "",{"version":3,"sources":["webpack://./assets/sass/designSystem/exports/_videoPlayButton.scss","webpack://./assets/reactApps/instaWidget/components/InstaCard/InstaCard.scss"],"names":[],"mappings":"AAAA,qCACC,6BAAA,CACA,8BAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,uBAAA,CACA,+BAAA,CACA,iBAAA,CCVD,oBACC,iBAAA,CACA,YAAA,CACA,wBAAA,CACA,eAAA,CAEA,wBAAA,CAGC,iHACC,YAAA,CAIF,+BACC,iBAAA,CACA,OAAA,CACA,gBAAA,CAGD,0BACC,0KAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CACA,SAAA,CACA,UAAA,CACA,uBAAA,CAEA,4BACC,aAAA,CACA,kBAAA,CAGD,+BACC,oBAAA,CACA,wBAAA,CACA,4BAAA,CACA,WAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
