import React from 'react';
import ReactDom from 'react-dom/client';
import App from './App';
import hidePreloader from '../common/utils/hidePreloader';

function renderApp() {
  const el = document.querySelector('react-instaWidget');
  if (!el) {
    return;
  }
  const fetchUrl = el.getAttribute('data-fetch');
  const hideHeader = Boolean(el.getAttribute('data-hide-header'));

  fetch(fetchUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((endpointData) => {
      if (endpointData.length > 0) {
        const inlineJson = el.querySelector('script').innerText;
        const inlineJsonData = JSON.parse(inlineJson);
        const root = ReactDom.createRoot(el);
        root.render(
          <App
            endpointData={endpointData}
            inlineJsonData={inlineJsonData}
            hideHeader={hideHeader}
          />,
        );
      }
    })
    .catch((error) => {
      if (error.message !== 'Load failed') {
        throw new Error(`An error occurred while fetching the Youtube Shorts endpoint: ${error.message}`);
      }
    })
    .finally(() => {
      hidePreloader(el);
    });
}

renderApp();
