import PropTypes from 'prop-types';

const InstaPropTypes = PropTypes.shape({
  thumbnails: PropTypes.shape({
    shorts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  embed_url: PropTypes.string.isRequired,
});

const EndpointDataPropTypes = PropTypes.arrayOf(InstaPropTypes);

const InlineDataPropTypes = PropTypes.shape({
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
});

const HideHeaderPropTypes = PropTypes.bool;

export {
  InstaPropTypes, EndpointDataPropTypes, InlineDataPropTypes, HideHeaderPropTypes,
};
